import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/tailwind.output.css";
import "./assets/css/glyphter-font/css/glyphter.css";
import "./assets/css/originals/games.css";
import "./assets/css/css/Glyphter.css";
import "./assets/css/new/css/Glyphter.css";
import "./assets/css/wingo/wingo.css"
import { BrowserRouter as Router } from "react-router-dom";
import { SidebarProvider } from "./context/SidebarContext.js";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker.js";
import _theme from "./theme.js";
import Loadable from "react-loadable";
import Loading from "./components/Loader.jsx";
import Loader from "./components/Loader.jsx";
import { APIProvider } from "./context/ApiContext.jsx";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./store/index.js";
// import WebApp from "@twa-dev/sdk";

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

// WebApp.ready();

const Application = Loadable.Map({
  loader: {
    ui: () => import("./App.js"),
    data: () =>
      // fetch("https://hub.dummyapis.com/delay?seconds=2").then((res) => {
      //   console.log(res);
      // }),
      new Promise((r) => setTimeout(r, 500)),
  },
  loading: Loading,
  render(loaded, props) {
    let Dashboard = loaded.ui.default;
    let data = loaded.data;
    return <Dashboard {...props} data={data} />;
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SidebarProvider>
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Loader />}>
          <Windmill theme={_theme} dark>
            <Application />
          </Windmill>
        </Suspense>
      </Router>
    </Provider>
  </SidebarProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
